import * as React from 'react';
import { IOnlyGoLiveCounterViewProps } from './only-go-live-counter';
import { useTimer } from 'react-timer-hook';

const MyTimer = ({ expiryTimestamp, props }: any): JSX.Element => {
    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn('onExpire called')
    });

    const { resources } = props;

    return (
        <div className='counter-wrapper'>
            <div className='counter-wrapper-box'>
                <div className='count-heading'>{resources.days}</div>
                <div className='counter'>{days}</div>
            </div>
            <div className='counter-wrapper-box'>
                <div className='count-heading'>{resources.hours}</div>
                <div className='counter'>{hours}</div>
            </div>
            <div className='counter-wrapper-box'>
                <div className='count-heading'>{resources.minutes}</div>
                <div className='counter'>{minutes}</div>
            </div>
            <div className='counter-wrapper-box'>
                <div className='count-heading'>{resources.seconds}</div>
                <div className='counter'>{seconds}</div>
            </div>
        </div>
    );
};
export default (props: IOnlyGoLiveCounterViewProps) => {
    const {
        config: { year, month, day, denseconds }
    } = props;
    const customDate = `${year}-${month}-${day}`;
    const time = new Date(customDate);
    const seconds = time.getSeconds() - Number.parseInt(denseconds ?? '0', 10);
    time.setSeconds(seconds);

    return (
        <>
            <MyTimer expiryTimestamp={time} props={props} />
        </>
    );
};
